<template>
  <div>
    <b-container fluid>
      <b-row align-v="center" class="side-panel-header-sub">
        <b-col cols="1" style="text-align: center; padding: 0;">
          <pf-vehicles-icon v-if="isBikeSelected" :size="24" fillColor="#949494" />
          <pf-panel-asc-icon v-if="isAscSelected" :size="24" />
        </b-col>
        <b-col cols="10">
          <span>{{ featureTitle }}</span>
        </b-col>
        <b-col cols="1" style="text-align: right;">
          <Avatar username="J R" :size="32" backgroundColor="#000" color="#ffffff" />
        </b-col>
      </b-row>
    </b-container>
    <EmbededMap v-if="isSidePanelOpen && isAscSelected" :focus="focus"></EmbededMap>
    <EBikeDashboard v-if="isSidePanelOpen && isBikeSelected" :ebike="selectedBike"></EBikeDashboard>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import EmbededMap from "@/components/map/EmbededMap";
import EBikeDashboard from "@/components/bike/EBikeDashboard";
import { store, OBJECT_TYPE } from "@/store.js";

export default {
  name: "feature",
  components: {
    Avatar,
    EmbededMap,
    EBikeDashboard,
  },
  computed: {
    isSidePanelOpen() {
      return store.isSidePanelOpen;
    },
    isBikeSelected() {
      return store.selectedObject != null && store.selectedObject.type == OBJECT_TYPE.EBIKE;
    },
    isAscSelected() {
      return store.selectedObject != null && store.selectedObject.type == OBJECT_TYPE.ASC;
    },
    featureTitle() {
      if (this.isBikeSelected) {
        return store.selectedObject.displayName + " : Adaptive Power Control";
      } else if (this.isAscSelected) {
        return store.selectedObject.name;
      } else {
        return "";
      }
    },
    selectedBike() {
      return store.selectedObject;
    }
  },
  methods: {},
  data() {
    return {
      focus: store.selectedRoute.actionMarkers.feature.container
    };
  },
  created() {}
};
</script>

<style scoped>
.side-panel-header-sub {
  text-align: left;
  height: 47px;
  font-weight: bold;
  font-size: 0.9rem;
}
</style>