<template>
  <div class="map-container" ref="map-container" v-resize.debounce.500="onMapUpdate">
    <RouteMap id="panelMap" :zoom="zoom" :interactive="false" :center="center" :parentLoadCallback="onMapUpdate"></RouteMap>
    <div class="feature-toggle" ref="feature-toggle">
      <b-form-checkbox v-model="enableSpeedLimit" name="check-button" switch size="lg" />
      <span class="toggle-desc">* Indiscriminate-Area</span>
    </div>
  </div>
</template>

<script>
import RouteMap from "@/components/map/RouteMap.vue";
import { store, mutations } from "@/store.js";
import * as turf from '@turf/turf';

export default {
  name: "EmbededMap",
  props: {
    focus: {
      type: Object,
      default: undefined
    }
  },
  components: {
    RouteMap
  },
  methods: {
      onMapUpdate(event) {
        if (!this.map && event && event.map) {
          this.map = event.map;
        }
        if (!this.map) {
          return;
        }
        var mcbcr = this.$refs["map-container"].getBoundingClientRect();
        var ftbcr = this.$refs["feature-toggle"].getBoundingClientRect();
        var heightTakenUp = mcbcr.bottom - ftbcr.top;
        var mapContainerH = mcbcr.height;

        var focusBbox = turf.bbox(this.focus);
        var featureBboxH = focusBbox[3] - focusBbox[1];
        var desiredBboxH = (featureBboxH * mapContainerH) / (mapContainerH - 8/5 * heightTakenUp);
        var bboxHDiff = desiredBboxH - featureBboxH, bboxHDiffUnit = bboxHDiff / 8;
        var desiredBbox = [focusBbox[0], focusBbox[1] - 6 * bboxHDiffUnit, focusBbox[2], focusBbox[3] + 2 * bboxHDiffUnit];

        this.map.fitBounds(desiredBbox);
      }
  },
  computed: {
    enableSpeedLimit: {
      get() {
        return store.routes[0].segments[0].features[0].speedLimitEnabled;
      },
      set(value) {
        store.routes[0].segments[0].features[0].speedLimitEnabled = value;
        mutations.refreshDetailLayoutVisibility(value);
        if (value) {
          mutations.setGeoFenceSpeedLimit(process.env.VUE_APP_GEO_FENCE_ID, process.env.VUE_APP_GEO_FENCE_SPEED_LIMIT);
        } else {
          mutations.deleteGeoFenceSpeedLimit(process.env.VUE_APP_GEO_FENCE_ID);
        }
      }
    }
  },
  data() {
    return {
      center: [store.routes[0].actionMarkers.feature.coordinates[0], store.routes[0].actionMarkers.feature.coordinates[1]],
      zoom: 0,
      map: undefined
    };
  },
  mounted() {
    this.onMapUpdate();
  }
};
</script>

<style scoped>
.map-container {
  height: calc(100% - 47px);
  flex: 1;
  background: url('~@/assets/img/Feature_Map_Background.png') repeat;
}
.feature-toggle {
  margin-top: -5rem;
  width: 100%;
  text-align: center;
}

.toggle-desc {
  position: relative;
  top: 1.45rem;
  left: 2.5rem;
}
</style>
